// E:\deportesyreservas\src\components\header\index.jsx

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { doSignOut } from '../../firebase/auth';

const Header = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useAuth();

    return (
        <nav className='flex flex-row gap-x-4 w-full z-20 fixed top-0 left-0 h-12 border-b place-content-between items-center bg-gray-200 px-4'>
            {
                userLoggedIn
                    ? (
                        <>
                            <Link className='text-sm text-blue-600 underline hover:text-blue-800 transition duration-200' to={'/home'}>Perfil</Link>
                            <button
                                onClick={() => {
                                    doSignOut().then(() => {
                                        navigate('/login');
                                    });
                                }}
                                className='text-sm text-blue-600 underline hover:text-blue-800 transition duration-200'
                            >
                                Logout
                            </button>
                        </>
                    )
                    : (
                        <>
                            <Link className='text-sm text-blue-600 underline hover:text-blue-800 transition duration-200' to={'/login'}>Login</Link>
                            <Link className='text-sm text-blue-600 underline hover:text-blue-800 transition duration-200' to={'/register'}>Register New Account</Link>
                        </>
                    )
            }
        </nav>
    );
};

export default Header;
