import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDRRSH2w3NlJZyFuNJkkHMSxzRaBHBjcvM",
    authDomain: "deportesyreservas.firebaseapp.com",
    projectId: "deportesyreservas",
    storageBucket: "deportesyreservas.firebasestorage.app",
    messagingSenderId: "544958129509",
    appId: "1:544958129509:web:eff1f82640d7b7524b2d8c",
    measurementId: "G-1KJEEQRWP3"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)



export { app, auth };
