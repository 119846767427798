// E:\deportesyreservas\src\pages\home\Home.jsx

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const {
        currentUser,
        nickname,
        gameLevel,
        preferredClub,
        handleNicknameUpdate,
        handleGameLevelChange,
        handlePreferredClubChange,
    } = useAuth();
    
    const [isEditing, setIsEditing] = useState(false);
    const [newNickname, setNewNickname] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (nickname) {
            setNewNickname(nickname);
        }
    }, [nickname]);

    // Manejar la actualización del nickname
    const handleUpdateNickname = () => {
        if (newNickname.trim() === "") {
            alert("El nickname no puede estar vacío.");
            return;
        }
        handleNicknameUpdate(newNickname);
        setIsEditing(false);
    };

    // Verificar que todos los campos estén llenos antes de redirigir
    const handleClubButtonClick = (clubName) => {
        if (!nickname || !gameLevel || !preferredClub) {
            alert("Por favor completa todos los campos antes de continuar.");
        } else {
            // Formatear el nombre del club adecuadamente para la URL
            const formattedClubName = clubName.toLowerCase().replace(/\s+/g, '-');
            navigate(`/club/${formattedClubName}`);
        }
    };

    if (!currentUser) {
        return <div>Cargando...</div>; // Mostrar un mensaje de carga mientras se cargan los datos del usuario
    }

    return (
        <div className='pt-14 px-4'>
            <h1 className='text-2xl font-bold mb-6'>Hola {currentUser?.email}, estás ahora registrado.</h1>
            <h2 className='text-xl mb-4'>Tu nickname es: {nickname}</h2>

            {!isEditing ? (
                <button
                    onClick={() => setIsEditing(true)}
                    className='bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300'
                >
                    Modificar Nickname
                </button>
            ) : (
                <>
                    <input
                        type="text"
                        value={newNickname}
                        onChange={(e) => setNewNickname(e.target.value)}
                        placeholder="Ingresa el nuevo nickname"
                        className='border rounded-lg px-3 py-2 mb-4 w-full text-gray-700'
                    />
                    <div className='space-x-4'>
                        <button
                            onClick={handleUpdateNickname}
                            className='bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300'
                        >
                            Guardar
                        </button>
                        <button
                            onClick={() => setIsEditing(false)}
                            className='bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300'
                        >
                            Cancelar
                        </button>
                    </div>
                </>
            )}

            {/* Selección del Nivel de Juego */}
            <div className='mt-8'>
                <label className='text-lg font-bold'>Nivel de juego:</label>
                <select
                    value={gameLevel}
                    onChange={(e) => handleGameLevelChange(e.target.value)}
                    className='ml-4 border rounded-lg px-3 py-2 text-gray-700'
                >
                    <option value="">Selecciona tu nivel de juego</option>
                    <option value="Principiante">Principiante</option>
                    <option value="Intermedio">Intermedio</option>
                    <option value="Avanzado">Avanzado</option>
                    <option value="Competitivo">Competitivo</option>
                    <option value="Profesional">Profesional</option>
                </select>
            </div>

            {/* Selección del Club Preferido */}
            <div className='mt-8'>
                <label className='text-lg font-bold'>Club preferido para jugar:</label>
                <select
                    value={preferredClub}
                    onChange={(e) => handlePreferredClubChange(e.target.value)}
                    className='ml-4 border rounded-lg px-3 py-2 text-gray-700'
                >
                    <option value="">Selecciona tu club preferido</option>
                    <option value="La Decanatura">La Decanatura</option>
                </select>
            </div>

            {/* Botón para ver quiénes quieren jugar en el club */}
            <div className='mt-8'>
                <button
                    onClick={() => handleClubButtonClick('La Decanatura')}
                    className='bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 transition duration-300'
                >
                    Ver quienes quieren jugar en el club La Decanatura
                </button>
            </div>
        </div>
    );
};

export default Home;
