import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';

const ProtectedRoute = ({ element }) => {
    const { userLoggedIn } = useAuth();

    if (!userLoggedIn) {
        // Si el usuario no está logueado, redirigir a la página de login
        return <Navigate to="/login" replace />;
    }

    // Si el usuario está logueado, mostrar la página solicitada
    return element;
};

export default ProtectedRoute;
