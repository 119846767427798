// E:\deportesyreservas\src\contexts\authContext\index.jsx

import React, { useContext, useState, useEffect } from "react";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { auth } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nickname, setNickname] = useState('');
  const [gameLevel, setGameLevel] = useState('');
  const [preferredClub, setPreferredClub] = useState('');

  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        setUserLoggedIn(true);
        await loadUserData(user.uid);

        // Verificar si todos los campos están completos para redirigir
        if (nickname && gameLevel && preferredClub) {
          const formattedClubName = preferredClub.toLowerCase().replace(/\s+/g, '-');
          navigate(`/club/${formattedClubName}`);
        }
      } else {
        setCurrentUser(null);
        setUserLoggedIn(false);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, [nickname, gameLevel, preferredClub]);

  async function loadUserData(uid) {
    try {
      const userDoc = doc(db, "users", uid);
      const userSnap = await getDoc(userDoc);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        setNickname(userData.nickname || '');
        setGameLevel(userData.gameLevel || '');
        setPreferredClub(userData.preferredClub || '');
      }
    } catch (error) {
      console.error("Error al cargar datos del usuario:", error);
    }
  }

  async function handleNicknameUpdate(newNickname) {
    if (currentUser) {
      if (newNickname.trim() === "") {
        alert("El nickname no puede estar vacío.");
        return;
      }
      try {
        const userDoc = doc(db, "users", currentUser.uid);
        await updateDoc(userDoc, {
          nickname: newNickname
        });
        setNickname(newNickname);
        alert("Nickname actualizado con éxito.");
      } catch (error) {
        console.error("Error al actualizar el nickname:", error);
        alert("Hubo un error al actualizar el nickname. Intenta de nuevo.");
      }
    }
  }

  async function handleGameLevelChange(selectedLevel) {
    if (currentUser) {
      try {
        const userDoc = doc(db, "users", currentUser.uid);
        await updateDoc(userDoc, {
          gameLevel: selectedLevel
        });
        setGameLevel(selectedLevel);
        alert("Nivel de juego actualizado con éxito.");
      } catch (error) {
        console.error("Error al actualizar el nivel de juego:", error);
        alert("Hubo un error al actualizar el nivel de juego. Intenta de nuevo.");
      }
    }
  }

  async function handlePreferredClubChange(selectedClub) {
    if (currentUser) {
      try {
        const userDoc = doc(db, "users", currentUser.uid);
        await updateDoc(userDoc, {
          preferredClub: selectedClub
        });
        setPreferredClub(selectedClub);
        alert("Club preferido actualizado con éxito.");
      } catch (error) {
        console.error("Error al actualizar el club preferido:", error);
        alert("Hubo un error al actualizar el club preferido. Intenta de nuevo.");
      }
    }
  }

  const value = {
    userLoggedIn,
    currentUser,
    nickname,
    gameLevel,
    preferredClub,
    handleNicknameUpdate,
    handleGameLevelChange,
    handlePreferredClubChange,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
