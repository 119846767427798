// src/App.js

import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { AuthProvider } from "./contexts/authContext";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import Header from "./components/header";
import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./pages/home/Home";
import ClubPage from "./pages/club/ClubPage";

function App() {
  const routesArray = [
    { path: "*", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/home", element: <ProtectedRoute element={<Home />} /> },
    { path: "/club/:clubName", element: <ProtectedRoute element={<ClubPage />} /> },
  ];

  let routesElement = useRoutes(routesArray);

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  useEffect(() => {
    // Manejar el evento beforeinstallprompt
    const beforeInstallPromptHandler = (e) => {
      // Prevenir el comportamiento predeterminado
      e.preventDefault();
      // Guardar el evento para disparar el prompt luego
      setDeferredPrompt(e);
      // Mostrar el botón de instalación
      setShowInstallButton(true);
    };

    // Registrar el evento beforeinstallprompt
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    // Eliminar el evento cuando el componente se desmonte
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Mostrar el cuadro de diálogo de instalación

      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('El usuario aceptó la instalación');
        setShowInstallButton(false); // Ocultar el botón después de la instalación
      } else {
        console.log('El usuario rechazó la instalación');
      }
      setDeferredPrompt(null); // Limpiar la referencia del evento
    }
  };

  return (
    <AuthProvider>
      <Header />
      <div className="w-full h-screen flex flex-col">
        {routesElement}
        {/* Banner de instalación */}
        {showInstallButton && (
          <div className="fixed bottom-4 right-4 flex items-center space-x-4" id="install-banner">
            <button
              id="install-button"
              onClick={handleInstallClick}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300"
            >
              Instalar App
            </button>
          </div>
        )}
      </div>
    </AuthProvider>
  );
}

export default App;
