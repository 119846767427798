// src/pages/club/ClubPage.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { getFirestore, collection, doc, getDoc, getDocs, addDoc, deleteDoc, Timestamp, query, where } from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ClubPage = () => {
    const { currentUser } = useAuth();
    const { clubName } = useParams(); // Obtener el nombre del club de la URL
    const db = getFirestore();

    const [selectedDate, setSelectedDate] = useState(null);
    const [timeslots, setTimeslots] = useState([]);

    // Formatear el nombre del club para mostrarlo correctamente en el encabezado
    const formattedClubName = clubName
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    // Cargar todos los timeslots futuros para el club específico cuando se monte el componente
    useEffect(() => {
        const loadTimeslots = async () => {
            try {
                const clubRef = doc(db, 'clubs', clubName.toLowerCase().replace(/\s/g, '-')); // Referencia dinámica del club
                const timeslotsRef = collection(clubRef, 'timeslots');
                const querySnapshot = await getDocs(timeslotsRef);

                if (!querySnapshot.empty) {
                    const currentTime = Timestamp.now();

                    // Filtrar y ordenar los timeslots
                    const timeslotData = await Promise.all(
                        querySnapshot.docs.map(async (docSnapshot) => {
                            const data = docSnapshot.data();
                            const userRef = doc(db, 'users', data.userId);
                            const userSnap = await getDoc(userRef);
                            let nickname = data.userId;
                            let gameLevel = 'Sin nivel';

                            if (userSnap.exists()) {
                                const userData = userSnap.data();
                                nickname = userData.nickname || data.userId;
                                gameLevel = userData.gameLevel || 'Sin nivel';
                            }

                            return {
                                id: docSnapshot.id,
                                time: data.time,
                                userId: data.userId,
                                nickname: nickname,
                                gameLevel: gameLevel,
                            };
                        })
                    );

                    // Filtrar los timeslots que son futuros
                    const futureTimeslots = timeslotData.filter((timeslot) => timeslot.time.toMillis() > currentTime.toMillis());

                    // Ordenar los timeslots del más cercano al más lejano
                    futureTimeslots.sort((a, b) => a.time.toMillis() - b.time.toMillis());

                    // Actualizar el estado con los timeslots futuros y ordenados
                    setTimeslots(futureTimeslots);
                } else {
                    setTimeslots([]); // Asegurar que no haya valores undefined
                }
            } catch (error) {
                console.error("Error al cargar los timeslots:", error);
            }
        };

        loadTimeslots();
    }, [db, clubName]);

    // Manejar la selección del timeslot
    const handleAddTimeslot = async () => {
        if (!selectedDate) {
            alert("Por favor selecciona una fecha y hora antes de continuar.");
            return;
        }

        const currentTime = Timestamp.now();
        if (selectedDate <= currentTime.toDate()) {
            alert("No puedes seleccionar un horario en el pasado.");
            return;
        }

        try {
            // Referencia al club
            const clubRef = doc(db, 'clubs', clubName.toLowerCase().replace(/\s/g, '-'));

            // Referencia a la subcolección timeslots
            const timeslotsRef = collection(clubRef, 'timeslots');

            // Convertir la fecha seleccionada a Timestamp de Firebase
            const timestamp = Timestamp.fromDate(selectedDate);

            // Verificar si ya existe un timeslot con la misma fecha y hora para el usuario actual
            const q = query(timeslotsRef, where('userId', '==', currentUser.uid), where('time', '==', timestamp));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                alert("Ya tienes un timeslot reservado en esta fecha y hora. Por favor selecciona otro horario.");
                return;
            }

            // Agregar el nuevo timeslot a la subcolección
            const newDocRef = await addDoc(timeslotsRef, {
                userId: currentUser.uid,
                time: timestamp
            });

            // Obtener los datos actualizados del usuario desde Firestore
            const userRef = doc(db, 'users', currentUser.uid);
            const userSnap = await getDoc(userRef);

            let nickname = currentUser.displayName || currentUser.email;
            let gameLevel = 'Sin nivel';

            if (userSnap.exists()) {
                const userData = userSnap.data();
                nickname = userData.nickname || nickname;
                gameLevel = userData.gameLevel || gameLevel;
            }

            // Actualizar la lista de timeslots en la UI
            setTimeslots((prev) => {
                const updatedTimeslots = [...prev, { id: newDocRef.id, userId: currentUser.uid, time: timestamp, nickname: nickname, gameLevel: gameLevel }];

                // Filtrar los timeslots que son futuros
                const futureTimeslots = updatedTimeslots.filter((timeslot) => timeslot.time.toMillis() > currentTime.toMillis());

                // Ordenar los timeslots para que el nuevo tome su lugar correspondiente
                futureTimeslots.sort((a, b) => a.time.toMillis() - b.time.toMillis());

                return futureTimeslots;
            });

            alert("Timeslot agregado con éxito.");
        } catch (error) {
            console.error("Error al agregar el timeslot:", error);
            alert("Hubo un error al agregar el timeslot. Intenta de nuevo.");
        }
    };

    // Manejar la eliminación del timeslot
    const handleDeleteTimeslot = async (timeslotId) => {
        try {
            // Referencia al club y al timeslot específico
            const clubRef = doc(db, 'clubs', clubName.toLowerCase().replace(/\s/g, '-'));
            const timeslotDocRef = doc(collection(clubRef, 'timeslots'), timeslotId);

            // Eliminar el timeslot de la base de datos
            await deleteDoc(timeslotDocRef);

            // Actualizar la lista de timeslots en la UI
            setTimeslots((prev) => prev.filter((timeslot) => timeslot.id !== timeslotId));

            alert("Timeslot eliminado con éxito.");
        } catch (error) {
            console.error("Error al eliminar el timeslot:", error);
            alert("Hubo un error al eliminar el timeslot. Intenta de nuevo.");
        }
    };

    // Función para verificar si hay coincidencia con otros jugadores
    const checkIfTimeslotMatches = (time) => {
        return timeslots.some(timeslot =>
            timeslot.time.isEqual(time) && timeslot.userId === currentUser.uid
        );
    };

    return (
        <div className='pt-14 px-4'>
            <h1 className='text-2xl font-bold mb-6'>Jugadores disponibles en {formattedClubName}</h1>

            {timeslots.length === 0 ? (
                <p>No hay jugadores que hayan seleccionado un horario futuro para jugar en {formattedClubName}.</p>
            ) : (
                <ul>
                    {timeslots.map((timeslot) => {
                        const isMatchingTimeslot = checkIfTimeslotMatches(timeslot.time);
                        const isCurrentUser = timeslot.userId === currentUser.uid;

                        return (
                            <li
                                key={timeslot.id}
                                style={{
                                    color: isMatchingTimeslot && !isCurrentUser ? '#22c55e' : 'inherit',
                                    fontWeight: isMatchingTimeslot && !isCurrentUser ? 'bold' : 'normal',
                                }}
                            >
                                {new Date(timeslot.time.toDate()).toLocaleString('en-US', {
                                    month: 'short',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                })} - {timeslot.nickname} ({timeslot.gameLevel})
                                {isMatchingTimeslot && !isCurrentUser && (
                                    <span style={{ color: '#22c55e', fontWeight: 'bold' }}> ✓</span>
                                )}
                                {isCurrentUser && (
                                    <button
                                        onClick={() => handleDeleteTimeslot(timeslot.id)}
                                        style={{
                                            marginLeft: '10px',
                                            color: 'red',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            border: 'none',
                                            background: 'none'
                                        }}
                                    >
                                        &minus;
                                    </button>
                                )}
                            </li>
                        );
                    })}
                </ul>
            )}

            <div className='mt-8'>
                <label className='text-lg font-bold'>Quiero jugar a esta hora:</label>
                <div className='mt-4'>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        showTimeSelect
                        timeIntervals={30}
                        dateFormat="Pp"
                        className='border rounded-lg px-3 py-2 text-gray-700'
                        placeholderText='Selecciona una fecha y hora'
                    />
                </div>
                <button
                    onClick={handleAddTimeslot}
                    className='mt-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300'
                >
                    Guardar horario
                </button>
            </div>
        </div>
    );
};

export default ClubPage;
